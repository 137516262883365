<template>
  <v-dialog
    :value="mostrar"
    content-class="dialog-inserir-veiculo"
    @click:outside="$emit('fechar')"
  >
    <v-card>
      <v-toolbar color="grey lighten-3" flat dense>
        <v-toolbar-title> Editar informações do alarme </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn text small @click="$emit('fechar')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-7">
        <validation-observer ref="observer">
          <v-row>
            <v-col class="d-none d-md-block md-3" />
            <v-col cols="12" md="6">
              <validation-provider v-slot="{ errors }" vid="dias_semana">
                <v-select
                  v-model="diasDaSemanaSelecionados"
                  :items="$store.state.layout.diasDaSemana"
                  item-text="descricao"
                  item-value="id"
                  :error-messages="errors"
                  label="Dias da Semana"
                  hide-details="auto"
                  multiple
                  outlined
                  dense
                >
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0" small>
                      <span>{{ item.descricao }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption">
                      (+{{ diasDaSemanaSelecionados.length - 1 }} outros)
                    </span>
                  </template>
                </v-select>
              </validation-provider>
            </v-col>
            <v-col class="d-none d-md-block md-3" />
          </v-row>
          <v-row>
            <v-col class="d-none d-md-block md-3" />
            <v-col cols="12" md="6">
              <v-menu
                v-model="menuDataValidade"
                :nudge-right="40"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="validade_alerta"
                  >
                    <v-text-field
                      v-model="dataValidadeFormatada"
                      v-mask="'##/##/####'"
                      label="Data Validade"
                      dense
                      autocomplete="off"
                      hide-details="auto"
                      outlined
                      :error-messages="errors"
                      append-icon="mdi-calendar"
                      @blur="
                        dataValidade = formatarDataApi($event.target.value)
                      "
                      @click:append="menuDataValidade = true"
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="dataValidade"
                  no-title
                  class="ma-0"
                  @input="menuDataValidade = false"
                />
              </v-menu>
            </v-col>
            <v-col class="d-none d-md-block md-3" />
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <btn-salvar outlined :carregando="carregandoSalvar" @click="salvar" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import formatarData from "@/utils/formatar-data-input";
import ocorrenciasApi from "@/api/ocorrencias";
export default {
  props: {
    mostrar: {
      type: Boolean,
      default: false,
    },
    diasSemanaEditar: {
      type: Array,
      default: () => [],
    },
    validadeAlerta: {
      type: String,
      default: "",
    },
    formatoIsoDataSemHora: {
      type: String,
      default: "",
    },
    idVeiculoEditar: {
      type: Number,
      default: null,
    },
    mostrarDialogEditarVeiculo: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      diasDaSemanaSelecionados: null,
      dataValidade: null,
      menuDataValidade: false,
      dataValidadeFormatada: null,
      menuHoraValidade: false,
      horaValidade: null,
      carregandoSalvar: false,
      compomenteDiasDaSemana: null,
    };
  },

  watch: {
    formatoIsoDataSemHora: {
      immediate: true,
      handler(data) {
        this.dataValidade = formatarData.formatoIsoDatasemHorario(data);
      },
    },
    diasSemanaEditar: {
      immediate: true,
      handler(data) {
        this.diasDaSemanaSelecionados = data;
      },
    },
    dataValidade: {
      immediate: true,
      handler(data) {
        this.dataValidadeFormatada = formatarData.ptBr(data);
      },
    },
  },

  methods: {
    async salvar() {
      try {
        this.carregandoSalvar = true;

        const dados = {
          dias_semana: this.diasDaSemanaSelecionados.join(","),
        };

        const id = this.idVeiculoEditar;
        if (this.dataValidade) {
          dados.validade_alerta = `${this.dataValidade}`;
        } else {
          dados.validade_alerta = null;
        }
        await ocorrenciasApi.editarVeiculo(id, dados);

        const diaSemanaAtualizado = dados.dias_semana;
        const validadeAlertaAtualizado = dados.validade_alerta;
        this.$emit("salvou", diaSemanaAtualizado, validadeAlertaAtualizado);
      } catch (e) {
        console.log(e);
        if (e.response.status === 422) {
          this.$refs.observer.setErrors(this.$erroApi.validacao(e));
          return;
        }
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregandoSalvar = false;
      }
    },

    formatarDataApi(data) {
      return formatarData.api(data);
    },
  },
};
</script>

<style lang="css">
.dialog-inserir-veiculo {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .dialog-inserir-veiculo {
    width: 50% !important;
  }
}
</style>
